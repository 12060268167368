import React from 'react';
import 'bootstrap';
import { graphql, StaticQuery } from  'gatsby';


export default () => (
  <StaticQuery
    query = {graphql`
      {
        payrollProviders: allMarkdownRemark(filter: { 
          fields: { 
            slug: { 
              regex: "//payroll-providers//" 
            }
          }
        }, sort: { fields: [frontmatter___title] order: ASC}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
        articles: allMarkdownRemark(filter: { 
          fields: { 
            slug: { 
              regex: "//articles//" 
            }
          }
        }, sort: { fields: [frontmatter___title] order: ASC}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }`
    }
    render={data => {
      return (
        <nav className="navbar navbar-expand-md navbar-dark pt-4 pb-4" style={{backgroundColor: '#188FFF'}}>
          <a className="navbar-brand ml-5" href="/">PayrollPro </a>
          <button className="navbar-toggler header" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto mr-5">
              <li className="nav-item">
                <a className="nav-link header" href="/showcase/top-10-payroll/">Top 10 Payroll Services</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link header" href="/payroll-providers/" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  Payroll Providers
                </a>
                <div className="dropdown-menu col-xs-12" aria-labelledby="navbarDropdown">
                {data.payrollProviders.edges.map((item, index) => {
                  return (
                    <a className="dropdown-item text-nowrap" key={index} href={item.node.fields.slug}>{item.node.frontmatter.title}</a>
                  );
                })}
                </div>
              </li>
              <li className="nav-item dropdown ">
                <a className="nav-link header" href="/tools/" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Tools</a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item text-nowrap tool" href="/tools/calculator/">Calculators</a>
                  <a className="dropdown-item text-nowrap tool" href="/tools/pay-stub">Pay Stub Templates</a>
                  <a className="dropdown-item text-nowrap tool" href="/tools/tax-table/">2024 Income Tax Tables</a>
                </div>
              </li>
              <li className="nav-item dropdown ">
                <a className="nav-link header" href="/articles/" id="navbarDropdown" role="button"  aria-haspopup="true" aria-expanded="false">Articles</a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                {data.articles.edges.slice(1).map((item, index) => {
                  return (
                    <a className="dropdown-item text-nowrap" key={index} href={item.node.fields.slug}>{item.node.frontmatter.title}</a>
                  );
                })}
                </div>
              </li>
              <li className="nav-item ">
                <a className="nav-link header" href="/faq/">FAQ</a>
              </li>
            </ul>
          </div>
        </nav>
      );
    }}
  />
)
